import React, { useContext, useEffect } from "react";
import { object, string, func } from "prop-types";
import { Layout, Menu, Tag, Alert, notification } from "antd";

import { NavLink } from "react-router-dom";

import {
  ExternalLinkNames,
  ExternalLinks,
  InternalLinkNames,
  InternalLinks
} from "../utils";
import { AppLogo } from "./app_logo";
import { ThemeContext } from "../contexts";
import { EnvironmentName } from "../config";
import { useStateWithSessionStorage } from "../hooks";

const SHOW_WARNING = EnvironmentName.toUpperCase() !== "PROD";
export const AppSider = ({ location, selectedThemeId, onChangeTheme }) => {
  const theme = useContext(ThemeContext);
  const [shownWarning, setShownWarning] = useStateWithSessionStorage(
    "App.ShownWarning"
  );

  //on load
  useEffect(() => {
    if (shownWarning === "") {
      setShownWarning(false);
    }
  }, [shownWarning, setShownWarning]);

  const renderMenuItemLink = ({
    icon,
    link,
    isInternal = true,
    showText = true
  }) => {
    const name = isInternal ? InternalLinkNames[link] : ExternalLinkNames[link];
    return (
      <Menu.Item key={link}>
        {isInternal ? (
          <NavLink to={link}>
            {icon}
            {showText && <span>{name}</span>}
          </NavLink>
        ) : (
          <a href={link} target={"_blank"} rel="noopener noreferrer">
            {icon}
            {showText && <span>{name}</span>}
          </a>
        )}
      </Menu.Item>
    );
  };
  useEffect(() => {
    if (!shownWarning && SHOW_WARNING) {
      notification.warning({
        message: "This is not a production environment!",
        placement: "topLeft",
        duration: null
      });
      setShownWarning(true);
    }
  }, [shownWarning, setShownWarning]);

  return (
    <div>
      {SHOW_WARNING && (
        <Alert message="This is not a production environment!" banner />
      )}
      <Layout.Header
        style={{
          backgroundColor: theme.siderBackgroundColor,
          height: "auto",
          lineHeight: "50px",
          margin: "0",
          padding: "0 8px"
        }}
      >
        <NavLink
          to={InternalLinks.Home}
          style={{
            marginRight: "8px",
            borderRight: `1px solid ${theme.borderColorSubtle}`
          }}
        >
          <AppLogo
            style={{
              display: "inline-block"
            }}
          />
          <span
            style={{
              fontWeight: "bold",
              color: theme.appMasterColor,
              fontSize: "20px",
              lineHeight: "28px",
              margin: "0px 16px 0 8px",
              verticalAlign: "middle"
            }}
          >
            pdBidr
          </span>
          {EnvironmentName && (
            <Tag color={theme.appMasterColor}>
              {EnvironmentName.toUpperCase()}
            </Tag>
          )}
        </NavLink>
        <Menu
          theme={"dark"}
          mode={"horizontal"}
          // selectedKeys={[location]}
          style={{
            backgroundColor: theme.siderBackgroundColor,
            display: "inline-block"
          }}
        >
          {renderMenuItemLink({
            //icon: <HomeOutlined />,
            link: InternalLinks.Home
          })}
          <Menu.SubMenu
            key={"reports"}
            title={"Reports"}
            // icon={<DollarOutlined />}
          >
            {renderMenuItemLink({
              link: InternalLinks.ReportDailySummary
            })}
            {renderMenuItemLink({
              link: InternalLinks.ReportUnitComparisonSummary
            })}
            {renderMenuItemLink({
              link: InternalLinks.ReportActualManualPerformance
            })}{" "}
            {renderMenuItemLink({
              link: InternalLinks.ReportUnitComparisonDetailed
            })}
            {renderMenuItemLink({
              link: InternalLinks.ReportActualManualLiability
            })}
          </Menu.SubMenu>
          {renderMenuItemLink({
            //icon: <BuildOutlined />,
            link: InternalLinks.Offers
          })}
          {renderMenuItemLink({
            // icon: <CopyOutlined />,
            link: InternalLinks.TemplateSets
          })}
          <Menu.SubMenu
            key={"history"}
            title={"History"}
            // icon={<ClockCircleOutlined />}
          >
            {renderMenuItemLink({
              link: InternalLinks.SolveList
            })}
            {renderMenuItemLink({
              link: InternalLinks.SolveCompositeResult
            })}
            {renderMenuItemLink({
              link: InternalLinks.SubmittedSets
            })}
            {renderMenuItemLink({
              link: InternalLinks.AckSets
            })}
            {renderMenuItemLink({
              link: InternalLinks.AutoStatusHistory
            })}
            {renderMenuItemLink({
              link: InternalLinks.DispatchTimesHistory
            })}
          </Menu.SubMenu>
          <Menu.SubMenu
            key={"settings"}
            title={"Settings"}
            // icon={<SettingOutlined />}
          >
            {renderMenuItemLink({
              link: InternalLinks.TraderParams
            })}
            {renderMenuItemLink({
              link: InternalLinks.StandingParams
            })}
            {/*{renderMenuItemLink({*/}
            {/*  link: InternalLinks.BidStringParams*/}
            {/*})}{" "}*/}
            {/*{renderMenuItemLink({*/}
            {/*  link: InternalLinks.GeneralParams*/}
            {/*})}*/}
            {renderMenuItemLink({
              link: InternalLinks.Users
            })}
            {renderMenuItemLink({
              link: InternalLinks.Roles
            })}
            {renderMenuItemLink({
              link: InternalLinks.BidAllocator
            })}
          </Menu.SubMenu>
        </Menu>
        <Menu
          theme={"dark"}
          mode={"horizontal"}
          // selectedKeys={[location]}
          style={{
            backgroundColor: theme.siderBackgroundColor,
            display: "inline-block",
            float: "right"
          }}
        >
          {renderMenuItemLink({
            //icon: <QuestionCircleOutlined />,
            showText: true,
            link: ExternalLinks.HelpHomepage,
            isInternal: false
          })}
          {renderMenuItemLink({
            // icon: <LogoutOutlined />,
            link: InternalLinks.Logout
          })}
        </Menu>

        {/*{!isCollapsed && (*/}
        {/*  <div style={{ width: "100%", textAlign: "center" }}>*/}
        {/*    <SwitchTheme*/}
        {/*      selectedThemeId={selectedThemeId}*/}
        {/*      onChangeTheme={onChangeTheme}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </Layout.Header>
    </div>
  );
};

AppSider.propTypes = {
  style: object,
  location: string,
  onChangeTheme: func.isRequired,
  selectedThemeId: string.isRequired
};
