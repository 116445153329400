import React, { useRef, useImperativeHandle, forwardRef } from "react";
import "./gridCellTooltipValidations.css";
import { arrayOf, shape, string } from "prop-types";

export const GridCellTooltipValidations = forwardRef((props, ref) => {
  //https://www.ag-grid.com/javascript-grid-tooltip-component/#example-custom-tooltip
  //https://www.ag-grid.com/react-hooks/

  const tooltipRef = useRef();
  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses: () => {
        return ["grid-cell-tooltip-validations"];
      }
    };
  });
  const { value } = props;
  if (!value || value.length === 0) return null;
  else
    return (
      <div
        className="grid-cell-tooltip-validations tooltip-shown"
        ref={tooltipRef}
      >
        <div style={{ padding: "8px" }}>
          {value.map((item, i) => (
            <div key={`v-${i}`}>
              {item.messageType}: {item.message}
            </div>
          ))}
          {/*{(!value || value.length === 0) && <div>No errors or warnings</div>}*/}
        </div>
      </div>
    );
});

GridCellTooltipValidations.propTypes = {
  value: arrayOf(
    shape({
      messageType: string.isRequired,
      message: string.isRequired
    })
  )
};
