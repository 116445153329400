import React, { useContext } from "react";
import { object, string } from "prop-types";

import { ThemeContext } from "../../contexts";

export const TitleForm = React.memo(({ style, title }) => {
  const theme = useContext(ThemeContext);
  return (
    <h3
      style={{
        color: theme.titleColor,
        borderBottom: "1px solid",
        borderColor: theme.borderColorSubtle,
        margin: "8px 0",
        padding: "8px 0",
        fontWeight: "bold",
        ...style
      }}
    >
      {title}
    </h3>
  );
});

TitleForm.propTypes = {
  style: object,
  title: string.isRequired
};
