import React, { useCallback, useState } from "react";
import { array, string } from "prop-types";
import { ActionBar } from "./ActionBar";
import { ActionConfigTemplateSet } from "./action_configs";
import { ActionTypeIds } from "./ActionTypeIds";
import { ActionDrawerLoadInto, ActionDrawerSubmit } from "./action_drawers";
import { TargetTypes } from "../../utils";

export const ActionsTemplateSet = React.memo(({ targets, templateSetId }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [action, setAction] = useState("");

  const onClickActionButton = useCallback(
    action => () => {
      setDrawerVisible(true);
      setAction(action);
    },
    [setDrawerVisible, setAction]
  );

  const onCloseActionDrawer = useCallback(
    () => {
      setDrawerVisible(false);
    },
    [setDrawerVisible]
  );

  return (
    <>
      <ActionBar
        onClickActionButton={onClickActionButton}
        config={ActionConfigTemplateSet}
      />
      {action === ActionTypeIds.LoadIntoTemplates && (
        <ActionDrawerLoadInto
          targets={targets}
          onDrawerClose={onCloseActionDrawer}
          drawerVisible={drawerVisible}
          targetLabel={templateSetId}
          targetType={TargetTypes.Template}
        />
      )}
      {action === ActionTypeIds.SubmitTemplates && (
        <ActionDrawerSubmit
          targets={targets}
          onDrawerClose={onCloseActionDrawer}
          drawerVisible={drawerVisible}
          targetLabel={templateSetId}
          targetType={TargetTypes.Template}
        />
      )}
    </>
  );
});

ActionsTemplateSet.propTypes = {
  targets: array,
  templateSetId: string.isRequired
};
